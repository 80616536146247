import { createStore } from 'redux'

const initialState = {
  groceries: [{ name: 'Test', checked: true }]
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'ADD_GROCERY':
          return {
            ...state,
            groceries: [
              ...state.groceries,
              {
                name: action.grocery,
                checked: false
              }
            ]
          }

        case 'CHECK_GROCERY':
          return {
            ...state,
            groceries: state.groceries.map(grocery => {
              if (grocery.name === action.name) {
                return {
                  ...grocery,
                  checked: true
                }
              }

              return grocery
            })
          }

        default:
            return state
    }
};

export const makeStore = () => {
  return createStore(appReducer, initialState)
}