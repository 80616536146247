import React from 'react'
import { connect } from 'react-redux'
import './home.css'

const GroceriesList = ({groceries, addGrocery, checkGrocery}) => {
  const [newGrocery, setNewGrocery] = React.useState('')
  return (
    <div>
      <form onSubmit={event => {
          event.preventDefault();
          addGrocery(newGrocery)
          setNewGrocery('')
        }}>
        <input onChange={event => setNewGrocery(event.target.value)} value={newGrocery} />
      </form>
      <ul>
        {groceries && groceries.map((grocery, index) => <li key={index} className={grocery.checked ? 'grocery-checked' : undefined} onClick={() => {
          checkGrocery(grocery.name)
        }}>{grocery.name}</li>)}
      </ul>
    </div>
  )
}

const Home = ({ groceries, addGrocery, checkGrocery }) => {

  React.useEffect(() => {
    fetch("/api/hello").then(res => res.text()).then(res => {
      console.log(res)
      document.title = res
  })
  }, [])

  return (
    <div>
      <title>Kitten</title>
      <link rel="icon" href="/favicon.ico" />

      <br/>
      <div className="groceries">
        <GroceriesList groceries={groceries} addGrocery={addGrocery} checkGrocery={checkGrocery}/>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    groceries: state.groceries
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addGrocery: (grocery) => {
      dispatch({type: 'ADD_GROCERY', grocery})
    },
    checkGrocery: (name) => {
      dispatch({type: 'CHECK_GROCERY', name })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)