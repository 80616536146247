import React from 'react';
import './app.css';
import { Provider as StoreProvider } from 'react-redux'
import { makeStore } from './store'

import Home from './pages/home'

const store = makeStore()

function App() {
  return (
    <StoreProvider store={store}>
      <Home />
    </StoreProvider>
  );
}

export default App;
